<template>
    <div class="footer">
        <div class="container">
            <div class="logo-wrapper">
                <img :src="'/logo.svg'" alt="">
                <h1 class="text-h1">EthnicMedia</h1>
            </div>

            <table class="table_contacts">
                <tbody>
                    <tr>
                        <td>{{ $t("name-address") }}:</td>
                        <td>{{ $t("name-address-value") }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("name-email") }}:</td>
                        <td><a href="mailto:inf@ethnicmedia.us">info@ethnicmedia.us</a></td>
                    </tr>
                    <tr>
                        <td>{{ $t("name-phone") }}:</td>
                        <td><a href="tel:+1-847-726-1414">+1-847-726-1414</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: {
    },
    setup() {
        const { t } = useI18n();

        return {
            t
        }
    }
});
</script>

<style scoped>
.footer {
    padding: 25px 0 34px 0;

    background: rgb(255, 255, 255);
}

.footer>.container {
    margin: 0 auto;
    padding: 0 24px;
    max-width: 1190px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer .table_contacts {
    margin-left: 175px;
}
@media (max-width:760px) {
    .footer>.container {
        justify-content: center;
        flex-direction: column-reverse;
    }
    .footer .table_contacts {
        margin-bottom: 34px;
    margin-left: 0px;
}
}
</style>