<template>
  <PrimaryHeader></PrimaryHeader>
  <main>
    <router-view />
  </main>
  <PrimaryFooter></PrimaryFooter>
</template>

<script>
import PrimaryHeader from './components/PrimaryHeader.vue'
import PrimaryFooter from './components/PrimaryFooter.vue'

export default {
  name: 'App',
  components: {
    PrimaryHeader,
    PrimaryFooter
  }
}
</script>

<style>
@import url("./styles/reset.css");
@import url("./styles/fonts.css");
@import url("./styles/globals.css");

#app {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
