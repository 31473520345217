// src/i18n.ts
import { createI18n } from 'vue-i18n';

import en from './locales/en.json';
import ru from './locales/ru.json';
import lt from './locales/lt.json';

function getStartingLocale() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
        return savedLocale;
    } else {
        const browserLocale = navigator.language || navigator.userLanguage;
        return browserLocale.split('-')[0];
    }
}

const i18n = createI18n({
    legacy: false,
    locale: getStartingLocale(),
    fallbackLocale: 'en',
    messages: {
        en: en,
        ru: ru,
        lt: lt
    }
})


export default i18n;
