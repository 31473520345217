<template>
    <div class="header">
        <div class="container">
            <div class="logo-wrapper">
                <img :src="'/logo.svg'" alt="">
                <h1 class="text-h1">EthnicMedia</h1>
            </div>
            <LanguageSwitcher></LanguageSwitcher>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import LanguageSwitcher from './LanguageSwitcher.vue';

export default defineComponent({
    components: {
        LanguageSwitcher
    },
});
</script>

<style scoped>
    .header {
        padding: 8px 0 10px 0;

        background: rgb(29, 29, 29);
    }
    .header > .container {
        margin: 0 auto;
        padding: 0 24px;
        max-width: 1190px;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>