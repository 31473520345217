<template>
    <select class="language-switcher" v-model="selectedLocale" @change="changeLocale">
        <option value="lt">LT</option>
        <option value="en">ENG</option>
        <option value="ru">RUS</option>
    </select>
</template>

<script>
export default {
    data() {
        return {
            selectedLocale: null
        };
    },
    created() {
        if (this.$i18n) {
            this.selectedLocale = this.$i18n.locale;
        } else {
            console.warn("i18n не доступен на момент создания компонента.");
        }
    },
    methods: {
        changeLocale() {
            if (this.$i18n) {
                this.$i18n.locale = this.selectedLocale;
                localStorage.setItem('locale', this.selectedLocale);
            } else {
                console.error("i18n не инициализирован. Переключение языка невозможно.");
            }
        }
    }
};
</script>

<style scoped>
.language-switcher {
    padding-right: 12px;
    appearance: none;

    background: #1D1D1D;
    background-image: url('~@/../public/dropdown-arrow.svg');
    background-repeat: no-repeat;
    background-position: right center;

    border: none;

    color: rgb(255, 255, 255);
    font-family: Bona Nova;
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: center;

    outline: none;
}
</style>
